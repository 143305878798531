































































































































































































































































import { computed, defineComponent, watch } from '@vue/composition-api';
import { ActionTypes } from '@/store/modules/tools/actions';
import store from '@/store';

export default defineComponent({
  name: 'MSponsorLinkInfo',
  props: {
    value: {
      type: Boolean,
      default: false,
      required: true
    },
    sponsorInfo: {
      type: Object,
      default: () => {},
      required: false
    }
  },
  setup(props: any, ctx: any) {
    const dialog = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const closeDialog = () => {
      localStorage.setItem(`sponsorInfo`, JSON.stringify(props?.sponsorInfo));
      dialog.value = false;
      store.dispatch(`tools/${ActionTypes.showBalloonPopup}`);
    };
    return {
      dialog,
      closeDialog
    };
  }
});
